import React, { useContext, useRef, useState } from 'react';
import { array, string } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { CSVLink } from 'react-csv';
import {
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReceivingLogTableDetailItem from './ReceivingLogTableDetailItem';
import PrinterPage from './PrinterPage';
import { filterSelectedData, buildDownloadData } from './receivingLog.utils';

import env from '../../environment';

import { SimWebContext } from '../../context/SimWeb.provider';

import './ReceivingLogPage.css';

const useStyles = makeStyles({
  downloadIcon: {
    position: 'absolute',
    right: '120px',
    top: '12px',
    fontSize: '37px',
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
  printIcon: {
    position: 'absolute',
    right: '100px',
    top: '25px',
    color: '#757575',
    cursor: 'pointer',
    fontSize: '20px',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
});

const ReceivingLogTable = ({ data, reportType }) => {
  const { isIOS, getMessage, storeInfo } = useContext(SimWebContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const isNorthAmerica = storeInfo?.isNorthAmerica;
  const useProNumber = !!(data[0] && data[0].proNumber);
  const classes = useStyles();
  const printRef = useRef();
  const csvLink = useRef();

  const isProd = !env.isProd();

  const headersForDownloadData = [
    {
      label: useProNumber ? getMessage('proNumber') : getMessage('documentNumber'),
      key: useProNumber ? 'proNumber' : 'deliveryNumber',
    },
    { label: isNorthAmerica ? 'STO/PO Number' : getMessage('poNumber'), key: 'poNumber' },
    ...!isProd && isNorthAmerica ? [{ label: getMessage('deliveryNumbers'), key: 'deliveryNumbers' }] : [],
    { label: getMessage('cartonNumber'), key: 'carton' },
    { label: getMessage('recvDate'), key: 'receivedDate' },
    { label: getMessage('upc'), key: 'upc' },
    { label: getMessage('unitsExpctd'), key: 'unitsExpected' },
    { label: getMessage('unitsRecd'), key: 'unitsReceived' },
    { label: getMessage('variance'), key: 'variance' },
    { label: getMessage('soh'), key: 'stockOnHand' },
  ];

  const headersForDownloadNoRecvDateData = headersForDownloadData.filter(obj => obj.key !== 'receivedDate');

  const searchFriendlyData = data?.map(item => ({
    ...item,
    searchHelper: JSON.stringify(item),
  }));

  const columns = () => {
    if (searchFriendlyData?.length > 0) {
      return [
        {
          name: useProNumber ? 'proNumber' : 'deliveryNumber',
          label: useProNumber ? getMessage('proNumber') : getMessage('documentNumber'),
        },
        {
          name: 'searchHelper',
          options: {
            display: 'excluded',
          },
        },
      ];
    }
    return [];
  };

  const customToolbar = () => (
    <>
      {data.length > 0 && (
        <div>
          {!isIOS && (
            <div data-testid="printIcon">
              <ReactToPrint
                trigger={() => (
                  <PrintIcon
                    className={classes.printIcon}
                  />
                )}
                content={() => printRef.current}
              />
            </div>
          )}
          <IconButton
            onClick={() => csvLink.current.link.click()}
            className={classes.downloadIcon}
            style={{ right: isIOS ? '70px' : '120px' }}
            size="large"
          >
            <CloudDownloadIcon />
          </IconButton>
          <CSVLink
            data={buildDownloadData(data, selectedItems, isIOS, useProNumber, storeInfo?.timezone)}
            headers={reportType === 'FULL' ? headersForDownloadData : headersForDownloadNoRecvDateData}
            filename="Receiving Log Report"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </div>
      )}
    </>
  );

  return (
    <>
      <MUIDataTable
        data={searchFriendlyData}
        columns={columns()}
        options={{
          filter: false,
          viewColumns: false,
          search: searchFriendlyData.length > 0,
          customSearch: (searchQuery, currentRow) => {
            let isFound = false;
            currentRow.forEach(col => {
              if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                isFound = true;
              }
            });
            return isFound;
          },
          pagination: false,
          selectableRows: searchFriendlyData?.length > 0 ? 'multiple' : 'none',
          print: false,
          download: false,
          textLabels: {
            body: {
              noMatch: getMessage('noData'),
            },
          },
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            setSelectedItems(rowsSelected);
          },
          rowsSelected: selectedItems,
          customToolbarSelect: () => customToolbar(),
          customToolbar: () => customToolbar(),
          expandableRows: true,
          expandableRowsOnClick: true,
          expandableRowsHeader: data?.length > 0,
          renderExpandableRow: (rowData, { dataIndex }) => (
            <>
              <tr>
                <td colSpan={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ '&.Mui-selected': { backgroundColor: 'red' }, '&.Mui-selected:hover': { backgroundColor: 'red' } }}>
                          <TableCell>{isNorthAmerica ? 'STO/PO Number' : getMessage('poNumber')}</TableCell>
                          {!isProd && isNorthAmerica
                            ? <TableCell>{getMessage('deliveryNumbers')}</TableCell>
                            : null}
                          <TableCell>{getMessage('carton')}</TableCell>
                          {reportType === 'FULL' ? <TableCell>{getMessage('recvDate')}</TableCell> : null}
                          <TableCell>{getMessage('upc')}</TableCell>
                          <TableCell>{getMessage('unitsExpctd')}</TableCell>
                          <TableCell>{getMessage('unitsRecd')}</TableCell>
                          <TableCell>{getMessage('variance')}</TableCell>
                          <TableCell>{getMessage('soh')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <ReceivingLogTableDetailItem data={data[dataIndex]} reportType={reportType} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </td>
              </tr>
            </>
          ),
        }}
      />
      <div ref={printRef}>
        <PrinterPage data={filterSelectedData(data, selectedItems)} reportType={reportType} isNorthAmerica />
      </div>
    </>
  );
};

ReceivingLogTable.propTypes = {
  data: array,
  reportType: string,
};

ReceivingLogTable.defaultProps = {
  data: [],
  reportType: 'MISSING',
};

export default ReceivingLogTable;
