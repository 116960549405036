import { environment } from '../environment';

export const routerUrls = {
  ADJUSTMENTS_REPORTS: 'ADJUSTMENTS_REPORTS',
  CSP_PRICE: 'CSP_PRICE',
  DELEDGE: 'DELEDGE',
  NIKE_API: 'NIKE_API',
  NIKE_API_PREPROD: 'NIKE_API_PREPROD',
  REPORTING_METRICS: 'REPORT_METRICS',
  RFID: 'RFID',
  SIMWEB_BFF: 'SIMWEB_BFF',
  SIM_REPORTS: 'SIM_REPORTS',
  SOH: 'SOH',
};

export const endpoints = {
  ADJUSTMENTS: {
    url: '/simweb_bff/adjustments/v1',
    name: 'Adjustments',
  },
  ADJUSTMENTS_DETAILS: {
    url: '/simweb_bff/adjustment_details/v1',
    name: 'Adjustments Details',
  },
  ADJUSTMENTS_FISCAL_PRINT: {
    url: '/store/invoice_print_requests/v1',
    jobUrl: '/store/invoice_print_requests_jobs/v1',
    name: 'Adjustments fiscal printing request',
  },
  ADJUSTMENTS_SUMMARY: {
    url: '/simweb_bff/adjustment_summary/v1',
    name: 'Adjustments Summary',
  },
  ASN_TRACKING: {
    url: '/sim/store_reports/v3/asn_tracking',
    name: 'ASN Tracking',
  },
  BINS: {
    url: '/simweb_bff/product_assignment_views/v1',
    name: 'BINS',
  },
  BIN_AUDITS: {
    url: '/store/bin_audits/v1',
    name: 'Bin Audits',
  },
  CPA_FACADE: {
    url: '/simweb_bff/cpa_facade_product/v1',
    name: 'CPA Facade',
  },
  CSP_PRICE: {
    url: '/price/selling_prices/v2',
    name: 'CSP Price',
  },
  DASHBOARD_SCANS: {
    url: '/simweb_bff/dashboard/v1',
    name: 'Dashboard SCAN Data',
  },
  DEVICE_ASSIGNMENT: {
    url: '/simweb_bff/device_assignment/v1',
    name: 'Device Assignment',
    actions: {
      profiles: '/profiles',
      sessions: '/sessions',
    },
  },
  DISPLAY_SCAN: {
    url: '/simweb_bff/display_scan/v1',
    name: 'Display Scan',
  },
  DOTCOM_AVAILABILITY: {
    url: '/simweb_bff/deliver/available_gtins/v3/secured',
    name: 'Nike.com Availability',
  },
  FISCAL_COMPLIANCE_DOCUMENT_FETCH: {
    url: '/store/invoice_print_document/v1',
    name: 'Fiscal Compliance Doc Fetch',
  },
  INBOUND_VISIBILITY: {
    url: '/simweb_bff/inbound_visibility/v1/',
    name: 'Inbound Visibility',
  },
  ITEM_ACTIVITY: {
    url: '/simweb_bff/item_activity/v1',
    name: 'Item Activity',
  },
  LEGACY_BERM: {
    url: '/simweb_bff/legacy_berm/v1',
    name: 'Simweb BERM login',
    actions: {
      refresh: '/refresh',
    },
  },
  MISSING_STYLES: {
    url: '/simweb_bff/missing_styles/v1',
    name: 'Missing Styles Sessions',
  },
  MISSING_STYLES_DETAILS: {
    url: '/simweb_bff/missing_styles_details/v1',
    name: 'Missing Styles Session Details',
  },
  OOS_AUDITS: {
    url: '/store/out_of_stock_audits/v1',
    name: 'OOS Audits',
  },
  PCS: {
    url: '/simweb_bff/product_catalog_product/v1',
    name: 'Product Catalogs Product',
  },
  PI_UPLOAD: {
    url: '/simweb_bff/pi_upload_status/v1',
    name: 'PI Upload',
  },
  PI_UPLOAD_REPORT: {
    url: '/simweb_bff/pi_upload_report/v1',
    name: 'PI Upload Report',
  },
  PI_UPLOAD_FILES: {
    url: '/simweb_bff/pi_upload_file/v1',
    name: 'PI Upload Files',
  },
  PRICE_CHANGES: {
    url: '/simweb_bff/price_changes/v1',
    name: 'Price Changes Details And Summary',
    actions: {
      live: '/live',
    },
  },
  PRODUCT_CONTENT: {
    url: '/merch/contents/v1/content',
    name: 'Product Content',
  },
  PRODUCT_IMAGES: {
    url: '/merch/contents/v1/images',
    name: 'Product Images',
  },

  // TODO delete when new product movement launches
  PRODUCT_MOVEMENT: {
    url: '/store/productmovement/v1',
    name: 'Product Movement',
  },
  PRODUCT_MOVEMENT_BFF: {
    url: '/simweb_bff/product_movement/v2',
    name: 'Product Movement',
  },
  RECEIVE_CARTONS: {
    url: '/ship/receive_cartons/v2',
    name: 'Receive Cartons',
  },
  RECEIVING_LOG_DETAILS: {
    url: '/sim/store_reports/v3/receiving_log_details',
    name: 'Receiving Log Details',
  },
  RECEIVING_LOG_SUMMARY: {
    url: '/sim/store_reports/v3/receiving_log_summary',
    name: 'Receiving Log Summary',
  },
  REFILL_COUNTS: {
    url: '/stock_management/refill_counts/v1',
    name: 'Refill Counts',
  },
  REPORTING_METRICS: {
    url: '/simweb_bff/reporting_metrics/v1',
    name: 'Reporting Metrics',
  },
  RFID_SESSIONS: {
    url: '/supply/rfid_sessions/v2',
    name: 'RFID Sessions',
  },
  SCAN: {
    url: '/simweb_bff/scan/v1',
    name: 'Scan Detail Report',
  },
  SCAN_DETAILS: {
    url: '/simweb_bff/scan_details/v2',
    name: 'Scan Details',
  },
  SCAN_SUMMARY: {
    url: '/simweb_bff/scans_summary/v1',
    name: 'Scan Summary',
  },
  SCAN_TIMER: {
    url: '/simweb_bff/scan_timer/v1',
    name: 'Scan Timer',
  },
  SIZE_COMPLIANCE: {
    url: '/store/size_compliance/v1',
    name: 'Size Compliance',
  },
  STOCK_ON_HAND: {
    url: '/simweb_bff/stock_on_hand/v1',
    name: 'Stock On Hand',
  },
  STOCKROOM_ORGANIZATION: {
    url: '/simweb_bff/stockroom_organization/v1',
    name: 'Stockroom Organization',
  },
  STORE_CONFIG: {
    url: '/store/configs/v2/store',
    name: 'Store Config',
  },
  STORE_CONFIG_POST: {
    url: '/store/configs/v2/scoped/',
    name: 'Store Config',
  },
  STORE_STOCK_V5: {
    url: '/supply/store_stock/v5',
    name: 'Store Stock V5',
  },
  STORE_VIEWS: {
    url: '/store/store_views/v2',
    name: 'Store Views',
  },
  STYLE_MANAGEMENT: {
    url: '/simweb_bff/style_management/v1',
    name: 'Style Management',
  },
  TASKS: {
    url: '/store/tasks/v2',
    name: 'Tasks v2 service',
  },
  TASKS_V3: {
    url: '/store/tasks/v3',
    name: 'Tasks v3 service',
  },
  TASK_VIEWS: {
    url: '/store/task_views/v1',
    name: 'Enriched Tasks "V2" service',
  },
  TASK_VIEWS_V3: {
    url: '/store/task_views/v3',
    name: 'Enriched Tasks V3 service',
  },
  TRANSFERS: {
    url: '/sim/store_reports/v3/transfer_report',
    name: 'Transfers',
  },
  TRANSFERS_ARG_FISCAL_PRINT: {
    url: '/store/remito_print_requests/v1',
    jobUrl: '/store/remito_print_requests_jobs/v1',
    name: 'Transfers Fiscal Print for Argentina',
  },
  TRANSFERS_FISCAL_PRINT: {
    url: '/store/waybill_print_requests/v1',
    jobUrl: '/store/waybill_print_requests_jobs/v1',
    name: 'Transfers Fiscal Print',
  },
  VERSION_CHECK: {
    url: '/simweb_bff/version_check/v1',
    name: 'UI Version Check',
  },
};

export const reportRegions = {
  'us-east-1': 'use1',
  'sa-east-1': 'sae1',
  'eu-west-1': 'euw1',
  'ap-southeast-1': 'apse1',
};

export const baseUrl = (router) => {
  let region = global.SIM_REGION;

  // We hard set test environment because no other environment except us-east-1 exists in test.
  if (environment === 'test') {
    region = 'us-east-1';
  }

  switch (router) {
    case routerUrls.DELEDGE: {
      const deledgeRegions = {
        'us-east-1': 'use1-deledge',
        'sa-east-1': 'sae1-deledge',
        'eu-west-1': 'euw1-deledge',
        'ap-southeast-1': 'apse1-deledge',
      };
      return `https://${deledgeRegions[region]}.${environment}.commerce.nikecloud.com`;
    }
    case routerUrls.CSP_PRICE:
      return environment === 'prod' ? 'https://globalpricing.nike.com' : 'https://globalpricing-qa.nike.com';
    case routerUrls.NIKE_API:
      return environment === 'prod' ? 'https://api.nike.com' : 'https://api-test.nikecloud.com';
    case routerUrls.NIKE_API_PREPROD:
      return environment === 'prod' ? 'https://api.nike.com' : 'https://api-preprod.nike.com';
    case routerUrls.REPORTING_METRICS:
      return `https://reporting-data-metrics-api.sim-${environment}.nikecloud.com`;
    case routerUrls.ADJUSTMENTS_REPORTS:
      return `https://adjustment-reports-api.sim-${environment}.nikecloud.com`;
    case routerUrls.RFID:
      return `https://scan-sessions.storerfid-${environment}.nikecloud.com`;
    case routerUrls.SIM_REPORTS:
      return `https://simreports-${reportRegions[region]}.sim-${environment}.nikecloud.com`;
    case routerUrls.SIMWEB_BFF: {
      if (process.env.REACT_APP_BFF_URL) {
        return process.env.REACT_APP_BFF_URL;
      }
      return environment === 'prod' ? 'https://simweb-bff.nike.com' : 'https://simweb-test-bff.nike.com';
    }
    case routerUrls.SOH:
      return environment === 'prod' ? ' https://stock-on-hand-report-api.sim-prod.nikecloud.com' : 'https://stock-on-hand-report-api.sim-test.nikecloud.com';
    default:
      throw new Error(`Cannot create url with ${router}!`);
  }
};

export const chooseTaskService = (configValue, isEnriched = false) => {
  if (configValue?.toLowerCase() === 'v3') {
    if (isEnriched) {
      return endpoints.TASK_VIEWS_V3;
    }
    return endpoints.TASKS_V3;
  }

  if (isEnriched) {
    return endpoints.TASK_VIEWS;
  }
  return endpoints.TASKS;
};
