/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo, useState } from 'react';
import { Dialog, Grid } from '@mui/material';

import ProgressBar from '../../../../components/progressBar/ProgressBar';
import ScanReportConfirmationModal from './ScanConfirmationModal';
import { scanStatuses, scanTypes } from '../../scanReportConstants';

import { SimWebContext } from '../../../../context/SimWeb.provider';
import { ScanTimerContext } from '../../../../context/ScanTimer.provider';
import { ScanDetailsContext } from '../ScanDetails.provider';

import './ScanReportManagerApproval.css';

const ScanReportManagerApproval = () => {
  const { getMessage, nikeStoreRoles, storeConfig } = useContext(SimWebContext);
  const { isScanNotProccessing, scanProgress } = useContext(ScanTimerContext);
  const {
    scanData, scanType, resumeCount, lastStatusOfScan, accuracy,
  } = useContext(ScanDetailsContext);

  const [approvalAction, setApprovalAction] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const accuracySubmissionThreshold = storeConfig?.accuracySubmissionThreshold?.value;

  const buttonDisableStatuses = ['TIMED_OUT', 'SUBMITTED', 'CANCELLED', 'SCANNING'];
  const scanStatusLabel = scanStatuses.getTranslatedLabel(lastStatusOfScan, getMessage);
  const disableButtons = buttonDisableStatuses.includes(lastStatusOfScan) || (lastStatusOfScan === 'IN_REVIEW' && scanProgress < 100);
  const disableSubmitButton = resumeCount === 2 && accuracy < accuracySubmissionThreshold;

  const onClick = (action) => {
    setApprovalAction(action);
    setOpenModal(true);
  };

  const progressBar = useMemo(() => {
    if (!scanStatuses.isInReview(lastStatusOfScan)) return null;

    return (
      <div className="scan-report-progressbar" style={{ width: '268px' }} data-testid="scan-report-progressbar">
        <ProgressBar completed={scanProgress} />
      </div>
    );
  }, [lastStatusOfScan, scanData, scanProgress, scanStatuses]);

  const submitButton = useMemo(() => (
    <div title={disableButtons ? getMessage('managerApprovalButtonDisabledMessage')
      : disableSubmitButton ? getMessage('managerApprovalButtonSubmissionDisabledMessage') : null}
    >
      <button
        onClick={() => onClick('SUBMITTED')}
        type="button"
        className="scan-report-submit-button"
        disabled={disableButtons || approvalAction !== '' || disableSubmitButton}
        data-testid="scan-report-submit-button"
      >
        {getMessage('submit')}
      </button>
    </div>
  ), [disableButtons, disableSubmitButton, approvalAction, getMessage]);

  const resumeButton = useMemo(() => (
    <div title={disableButtons ? getMessage('managerApprovalButtonDisabledMessage') : null}>
      <button
        onClick={() => onClick('SCANNING')}
        type="button"
        className="scan-report-resume-button"
        disabled={isScanNotProccessing ? false : ((disableButtons || approvalAction !== '') || resumeCount === 2)}
        data-testid="scan-report-resume-button"
      >
        {getMessage('resume')}
      </button>
    </div>
  ), [disableButtons, approvalAction, resumeCount, isScanNotProccessing, getMessage]);

  const cancelButton = useMemo(() => (
    <div title={disableButtons ? getMessage('managerApprovalButtonDisabledMessage') : null}>
      <button
        onClick={() => onClick('CANCELLED')}
        type="button"
        className="scan-report-cancel-button"
        disabled={isScanNotProccessing ? false : disableButtons || approvalAction !== ''}
        data-testid="scan-report-cancel-button"
      >
        {getMessage('cancel')}
      </button>
    </div>
  ), [disableButtons, approvalAction, isScanNotProccessing, getMessage]);

  return (
    <div data-testid="scan-report-manager-approval">
      <Grid container direction="row" style={{ marginBottom: '15px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p className="scan-report-status" data-testid="scan-report-status-wrapper">
            {`${getMessage('statusOfScan')}: ${scanStatusLabel}`}
          </p>
        </div>
        {progressBar}
      </Grid>
      {(scanTypes.isFullStore(scanType) || scanTypes.isCycleCount(scanType)) && nikeStoreRoles?.maxValue >= 40
        ? (
          <>
            <div className="manager-approval-button-wrapper" data-testid="manager-approval-button-wrapper">
              <p>{getMessage('managerApproval')}</p>
              {submitButton}
              {resumeButton}
              {cancelButton}
            </div>
            <Dialog
              open={openModal}
              fullScreen={false}
              maxWidth="sm"
              fullWidth
            >
              <ScanReportConfirmationModal
                approvalAction={approvalAction}
                setOpenModal={setOpenModal}
                setApprovalAction={setApprovalAction}
              />
            </Dialog>
          </>
        ) : null}
    </div>
  );
};

export default ScanReportManagerApproval;
