import { camelCase } from 'lodash';

/**
 * A helper function to get the legend labels and colors for the grouped bar chart
 * @param {object} data the graph data
 * @param {string} aggregatedBy the key to aggregate the data by
 * @param {function} getMessage the function to translate the key
 */
export const getLegendData = (data, aggregatedBy, getMessage) => {
  const labels = [];
  const legend = [];

  Object.keys(data?.[aggregatedBy] ?? [])?.map(key => {
    const colorKey = `--${aggregatedBy}_${key}`;

    if (labels.includes(key)) return;
    labels.push(camelCase(key));
    legend.push({
      label: getMessage(camelCase(key)),
      colors: {
        start: getComputedStyle(document.documentElement).getPropertyValue(colorKey),
        end: getComputedStyle(document.documentElement).getPropertyValue(`${colorKey}2`),
      },
    });
  });

  return legend;
};

/**
 * A helper function to get the total units and value for the grouped bar chart
 * @param {object} data the graph data
 * @param {string} aggregatedBy the key to aggregate the data by
 * @param {string} varianceType the type of variance to display: missing or extra
 * @param {string} selectedDivision the selected division name to filter the data
 * @param {string} selectedBarLabel the selected bar item to filter the data
 */
export const getTotalUnitsAndValue = (data, aggregatedBy, varianceType, selectedDivision, selectedBarLabel) => {
  let totalUnits = 0;
  let totalValue = 0;

  if (selectedDivision && selectedBarLabel) {
    const selectedData = data?.[aggregatedBy]?.[selectedBarLabel]?.[selectedDivision]?.[varianceType];
    totalUnits = selectedData?.units || 0;
    totalValue = selectedData?.value || 0;
  } else if (data?.[aggregatedBy]) {
    Object.keys(data?.[aggregatedBy])?.map(item => {
      const divisions = Object.keys(data?.[aggregatedBy][item]);
      totalValue += divisions?.map(division => data?.[aggregatedBy][item][division]?.[varianceType]?.value || 0).reduce((acc, curr) => acc + curr, 0);
      totalUnits += divisions?.map(division => data?.[aggregatedBy][item][division]?.[varianceType]?.units || 0)
        .reduce((acc, curr) => acc + curr, 0);
    });
  }

  return { totalUnits, totalValue };
};

/**
 * A helper function to get the subtitle
 * @param {string} varianceType the type of variance to display: missing or extra
 * @param {string} aggregatedBy the key to aggregate the data by
 * @param {funciton} getMessage the function to translate the key
 */
export const getSubtitles = (varianceType, aggregatedBy, getMessage) => {
  if (varianceType === 'extra') {
    return (aggregatedBy === 'genderAge') ? getMessage('varianceGraphSubtitleVarianceExtra') : getMessage('varianceGraphSubtitleVarianceExtraCategory');
  }
  if (varianceType === 'missing') {
    return (aggregatedBy === 'genderAge') ? getMessage('varianceGraphSubtitleVarianceMissing') : getMessage('varianceGraphSubtitleVarianceMissingCategory');
  }
  return '';
};

/**
 * A helper function to transform the graph data for the grouped bar chart
 * @param {object} data the graph data
 * @param {string} aggregatedBy the key to aggregate the data by
 * @param {array} divisions a list of divisions
 * @param {string} varianceType the type of variance to display: missing or extra
 * @param {string} selectedDivision the selected division name to filter the data
 * @param {string} selectedBarLabel the selected bar item to filter the data
 * @param {function} getMessage the function to translate the key
 */
export const transformGraphData = (data, aggregatedBy, divisions, varianceType, selectedDivision, selectedBarLabel, getMessage) => {
  const datasets = [];

  const divisionDataMap = divisions.reduce((acc, division) => {
    acc[division] = {
      hasData: false,
    };
    return acc;
  }, {});

  // determines if a division has data
  Object.keys(data?.[aggregatedBy] ?? [])?.map(key => {
    divisions?.map(division => {
      if (divisionDataMap[division].hasData) return;
      const divisionData = data[aggregatedBy][key][division];
      const isEmpty = divisionData?.[varianceType]?.units === 0 && divisionData?.[varianceType]?.value === 0;
      divisionDataMap[division].hasData = !isEmpty;
    });
  });

  Object.keys(data?.[aggregatedBy] ?? [])?.map(key => {
    const colorKey = `--${aggregatedBy}_${key}`;
    const label = getMessage(camelCase(key));
    const item = {
      label,
      borderColor: [],
      backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(`${colorKey}2`),
      borderWidth: [],
      data: [],
    };

    divisions?.map(division => {
      if (!divisionDataMap[division].hasData) return;

      const divisionData = data[aggregatedBy][key][division];

      item.data.push(divisionData?.[varianceType]?.units || 0);
      if (division === selectedDivision?.toLowerCase() && key === selectedBarLabel) {
        item.borderWidth.push(2);
        item.borderColor.push('#ffffff');
      } else {
        item.borderWidth.push(0);
        item.borderColor.push(getComputedStyle(document.documentElement).getPropertyValue(colorKey));
      }
    });

    datasets.push(item);
  });

  const translatedDivisions = Object.keys(divisionDataMap)
    .filter(key => divisionDataMap[key].hasData)
    .map(division => divisionDataMap[division].hasData && getMessage(division));

  const graphData = {
    labels: translatedDivisions,
    datasets,
  };

  return graphData;
};
