import writeXlsxFile from 'write-excel-file';
import React, { useContext, useState, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import Title from './Title';
import PickListToolBar from '../picklistModal/PickListToolBar';
import PicklistModal from '../picklistModal/PicklistModal';
import PicklistFormModal from '../picklistModal/PicklistFormModal';
import { newRelicAction } from '../../../utils/newRelicPageActions';

import { SimDateTime } from '../../../utils/datetime';
import getFormattedPrice from '../../../utils/getFormattedPrice';
import { sortSizes } from '../../../utils/sort/sortSizes';
import { sortByStockOnHand } from '../../../utils/sort/sortByStockOnHand';
import { sortByStyleColorAndSize } from '../../../utils/sort/sortByStyleColorAndSize';
import createBinsFilter from '../../../components/customTableFilters/CustomBinFilter';
import { buildDownloadHeaders, buildDownloadData } from '../utils/sizeComplianceTable.utils';
import { getRfidTableHeader } from '../../../components/RFIDTableHeader';
import { createProductRefillPickList } from '../../../axios/taskService.axios';
import DIVISIONS from '../../../constants/divisions';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { SizeComplianceContext } from '../SizeCompliance.provider';

export const tableDataKey = {
  division: 0,
  category: 1,
  gender: 2,
  productDescription: 3,
  styleColor: 4,
  size: 5,
  locationSOH: 6,
  rfidSoh: 7,
  totalSOH: 7,
  bin: 8,
  price: 9,
  gtin: 10,
  fohSOH: 12,
  bohSOH: 13,
  offsiteSOH: 14,
};

/**
 * Summary table to display size compliance data
 */
const SizeComplianceTable = () => {
  const {
    currencyCode, locale, getMessage, isOffsiteEnabled, isOffsiteInactive, isPrintingEnabled, storeId,
    athleteId, storeConfig, isAdmin, store, country,
  } = useContext(SimWebContext);
  const { data, loading } = useContext(SizeComplianceContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isCreated, setCreated] = useState(false);
  const [picklistItems, setPicklistItem] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const MAX_SELECTABLE_ROW_COUNT = 100;
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const isSizeComplianceRefillsEnabled = storeConfig?.isSizeComplianceRefillsEnabled?.value;
  const [pickListLoading, setPickListLoading] = useState(false);
  const taskService = storeConfig?.tasksVersion?.value;

  const setModals = (status) => {
    if (status === 'SUCCESS') {
      setCreated(true);
      setIsFormOpen(false);
      setIsOpen(true);
    }
    if (status === 'FAILED') {
      setCreated(false);
      setIsFormOpen(false);
      setIsOpen(true);
    }
  };

  const setSelectedItems = async (selectedRows, displayData, taskService) => {
    const items = await selectedRows?.data?.map((row) => {
      const dataIndex = displayData?.findIndex(_ => _?.dataIndex === row?.dataIndex);
      const selectedData = displayData[dataIndex]?.data;
      return {
        gtin: selectedData[tableDataKey?.gtin],
        quantityRequested: 1,
        status: 'REQUESTED',
        ...(taskService?.toLowerCase() === 'v3' ? { itemType: 'REGULAR_PRODUCT' } : { type: 'REGULAR_PRODUCT' }),
        ...(taskService?.toLowerCase() === 'v3' && {
          fulfillments: [
            {
              status: 'REQUESTED',
              quantity: 1,
            },
          ],
        }),
      };
    });
    setPicklistItem(items);
    setIsFormOpen(true);
  };

  const createPicklist = async (name) => {
    const variables = {
      storeId,
      name,
      items: picklistItems,
      athleteId,
    };
    setPickListLoading(true);

    try {
      const productRefill = await createProductRefillPickList(variables, taskService, 'Size Compliance');

      if (productRefill) {
        newRelicAction('size-compliance-picklist', {
          picklistName: name,
          units: picklistItems.length,
          user: athleteId,
          country,
        });
        setModals('SUCCESS');
      } else {
        setModals('FAILED');
      }
    } catch (error) {
      setModals('FAILED');
    }
    setPickListLoading(false);
  };

  const allBins = data && Array.from(new Set(data?.flatMap(_ => _.binInfo.map(b => b))));
  const rfidColumn = getRfidTableHeader('locationSOH', getMessage('rfidSoh'), 'sizeCompliance-table', rfidEnabled, isOffsiteEnabled, isOffsiteInactive);

  const columns = [
    {
      name: 'division',
      label: getMessage('division'),
    },
    {
      name: 'category',
      label: getMessage('category'),
    },
    {
      name: 'gender',
      label: getMessage('gender'),
    },
    {
      name: 'productDescription',
      label: getMessage('description'),
    },
    {
      name: 'styleColor',
      label: getMessage('style-color'),
    },
    {
      name: 'size',
      label: getMessage('size'),
    },
    rfidColumn,
    {
      name: 'rfidSOH',
      label: getMessage('soh'),
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          'data-testid': 'columnStep',
        }),
      },
    },
    {
      name: 'bin',
      label: getMessage('bin'),
      options: {
        filterType: 'custom',
        ...createBinsFilter({ getMessage, bins: allBins }),
        customBodyRender: (value) => {
          const bins = value.split(', ');
          const sortedBins = bins.sort((a, b) => a.localeCompare(b, { numeric: true, sensitivity: 'base' }));
          return <>{sortedBins.join(', ')}</>;
        },
      },
    },
    {
      name: 'price',
      label: getMessage('price'),
      options: {
        customBodyRender: value => (
          <>{getFormattedPrice(value, locale, currencyCode)}</>
        ),
      },
    },
    {
      name: 'gtin',
      label: getMessage('upc'),
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'totalSOH',
      label: 'Total SOH',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'fohSOH',
      label: 'FOH SOH',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'bohSOH',
      label: 'BOH SOH',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'offsiteSOH',
      label: 'Offsite SOH',
      options: {
        display: 'excluded',
      },
    },
  ];

  const rows = useMemo(() => sortByStyleColorAndSize(data?.map(((_) => {
    const sohLocationValues = `${_.salesFloor || '-'} / ${_.stockRoom || '-'}${isOffsiteEnabled ? ` / ${_.offsite || '-'}` : ''}`;

    return {
      division: getMessage(DIVISIONS.getTranslation(_?.division)),
      category: _?.retailCategory || '-',
      gender: _?.genderAge || '-',
      productDescription: _?.description || '-',
      styleColor: _?.productCode || '-',
      size: _?.size || '-',
      locationSOH: sohLocationValues,
      rfidSOH: (_?.soh || _?.rfidSoh) || '-',
      bin: _?.binInfo && _.binInfo?.length ? _.binInfo.map(b => b).join(', ') : '-',
      price: _?.price,
      gtin: _?.gtin,
      fohSOH: _?.salesFloor,
      bohSOH: _?.stockRoom,
      offsiteSOH: _?.offsite,
    };
  })), 'asc'), [data]);

  const options = {
    selectableRows: 'none',
    selectableRowsHeader: true,
    rowsSelected: selectedRows,
    onRowsSelect: (rowsSelected, allRows) => {
      const selectable = allRows.slice(0, MAX_SELECTABLE_ROW_COUNT);
      setSelectedRows(selectable.map(row => row.dataIndex));
    },
    isRowSelectable: (dataIndex, selectedRows) => {
      const selected = selectedRows.data.find(item => item.dataIndex === dataIndex);
      return !!selected || selectedRows.data.length < MAX_SELECTABLE_ROW_COUNT;
    },
    customToolbarSelect: (selectedRows, displayData) => (
      <PickListToolBar createPickList={() => setSelectedItems(selectedRows, displayData, taskService)} />
    ),
    textLabels: {
      toolbar: {
        downloadCsv: 'Download XLS',
      },
      body: { noMatch: getMessage('noResults') },
    },
    print: isPrintingEnabled,
    download: true,
    // eslint-disable-next-line prefer-template
    onDownload: (buildHead, buildBody, newColumns, newData) => {
      const headerRow = buildDownloadHeaders(newColumns, isOffsiteEnabled);
      const dataRows = buildDownloadData(newData, tableDataKey, isOffsiteEnabled);
      try {
        writeXlsxFile([headerRow, ...dataRows], { fileName: `Size Compliance-${store}-${SimDateTime.toUtcISO()}.xlsx` });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return false;
    },
    viewColumns: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [15, 25, 50, 100],
    sort: true,
    customSort: (data, dataIndex, rowIndex) => {
      if (dataIndex === tableDataKey.size) {
        return data && sortSizes(data, rowIndex, dataIndex);
      }
      if (dataIndex === tableDataKey.locationSOH) {
        return data && sortByStockOnHand(data, rowIndex, dataIndex, true);
      }
      if (dataIndex === tableDataKey.totalSOH) {
        return data && sortByStockOnHand(data, rowIndex, dataIndex, false);
      }
      // eslint-disable-next-line no-nested-ternary
      return data && data.sort((a, b) => (rowIndex === 'desc'
        ? (a.data[dataIndex] < b.data[dataIndex] ? 1 : -1)
        : (b.data[dataIndex] > a.data[dataIndex] ? -1 : 1)));
    },
    filter: false,
    search: false,
  };

  const refillOptions = {
    ...options,
    selectableRows: 'multiple',
  };

  return (
    <div data-testid="sizeCompliance-summary-table">
      <MUIDataTable
        title={<Title loading={loading} />}
        data={rows}
        columns={columns}
        options={(isSizeComplianceRefillsEnabled || isAdmin) ? refillOptions : options}
      />
      <PicklistModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isCreated={isCreated}
        setCreated={setCreated}
      />
      <PicklistFormModal
        createPicklist={createPicklist}
        isOpen={isFormOpen}
        loading={pickListLoading}
        setIsOpen={setIsFormOpen}
        units={picklistItems.length}
      />
    </div>
  );
};

export default SizeComplianceTable;
